* {
    box-sizing: border-box;
  }
  
  body {
    font-family: sans-serif;
    margin: 0;
    padding: 0;
  }
  
  h1 {
    margin-top: 0;
    font-size: 22px;
  }
  
  h2 {
    margin-top: 0;
    font-size: 20px;
  }
  
  h3 {
    margin-top: 0;
    font-size: 18px;
  }
  
  h4 {
    margin-top: 0;
    font-size: 16px;
  }
  
  h5 {
    margin-top: 0;
    font-size: 14px;
  }
  
  h6 {
    margin-top: 0;
    font-size: 12px;
  }
  
  code {
    font-size: 1.2em;
  }
  
  ul {
    padding-inline-start: 20px;
  }
  
  * {
    box-sizing: border-box;
  }

  .welcome-text {
    font-size: 5vb;
    font-family: 'Outfit';
    margin-bottom: 5%;
    margin-top: -10%;
  }

  .login-logo {
    margin: auto;
    display: block;
  }

  .alert {
    font-size: xx-large;
    width: auto;
    height: auto;
  }

  .register{
    margin-top: -15%;
  }

  @media (max-width: 500px){
    .register{
      margin-top: 0%;
    }
    .welcome-text{
      margin-top: 5%;
    }
  }

  .login-container {
    margin-top: 5%;
    display: block;
    max-width: 100%;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    height: 62vh; /* Make the container take the full viewport height */
    padding-left: 35%;
    padding-right: 35%;
  }

  @media (max-width: 500px){
    .login-container{
      padding-left: 0%;
      padding-right: 0%;
    }
  }
  
  .login {
    text-align: center;
    padding: 20px;
    border-radius: 5px;
  }
  
  .inputBox {
    width: 100%;
    margin: .1% 0; 
  }


    .login-logo {
      margin-top: 2%;
      width: auto;
      height: 10vb;
    }


  .align-left {
    text-align: left;
  }

  .reset-password-text {
    font-size: 20;
    text-wrap:wrap;
    font-family: 'Outfit';
  }
  
  
  